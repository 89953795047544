import { Button } from '@mui/material';
import React from 'react';
import { useTheme, useMediaQuery } from '@mui/material';
import PricingPlans from './PricingPlans';
import { useNavigate } from 'react-router-dom';

const PricingHero = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const styles = {
        container: {
            width: '100%',
            backgroundColor: 'white',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
        },
        starContainer: {
            marginTop: '3%',
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
        },
        reviewText: {
            marginLeft: '10px',
            color: 'black',
            fontSize: '16px',
            fontWeight: 'bold',
        },
        starIcon: {
            color: '#FFD700',
        },
        buttonContainer: {
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '15px',
            boxShadow: 'none',
            marginTop: '40px',
            zIndex: '2',
        },
        startButton: {
            background: '#EA2024',
            color: 'white',
            padding: '15px 20px',
            borderRadius: '15px',
            boxShadow: 'none',
            fontSize: isMobile ? '10px' : 'inherit',
            zIndex: 10,
            '&:hover': {
                backgroundColor: '#d0191f',
            },
        },
        consultButton: {
            padding: '15px 20px',
            borderRadius: '15px',
            boxShadow: 'none',
            borderColor: '#EA2024',
            color: '#EA2024',
            backgroundColor: 'transparent',
            fontSize: isMobile ? '10px' : 'inherit',
            zIndex: 10,
            '&:hover': {
                borderColor: '#d0191f',
            },
        },
        vectorImage: {
            position: 'absolute',
            left: '0',
            top: isMobile ? '14%' : '5%',
        },
        trustText: {
            position: 'absolute',
            bottom: '2%', // Position it near the bottom
            color: 'black',
            fontSize: '15px',
            fontWeight: '500',
            textAlign: 'center',
            zIndex: '3',
        },
    };

    return (
        <div style={styles.container} className='bg-gradient'>
            <div>
                <h2 style={{ fontSize: isMobile ? '26px' : '40px', textAlign: 'center', lineHeight: '1.1', color: '#EA2024', margin: '6% auto 2%', width: isMobile ? '90%' : '70%' }}>
                    All the Services You Need for Your US Company, <span style={{ color: 'black' }}>Now in One Place</span>
                </h2>
                <div style={{ fontSize: isMobile ? '13px' : '17px', fontWeight: '400', textAlign: 'center', lineHeight: '25.2px', marginTop: isMobile ? '5%' : '' }}>
                    Launch your business online abroad with our team's support, from anywhere.
                </div>
                <div style={styles.buttonContainer}>
                    <Button variant="contained" sx={{ ...styles.startButton, cursor: 'pointer' }} onClick={() => navigate('/start')}>
                        Start my business
                    </Button>
                    <a href={'https://wa.link/wh0p7a'} target="_blank" rel="noopener noreferrer">
                        <Button variant="outlined" sx={styles.consultButton}>
                            WhatsApp Support
                        </Button>
                    </a>
                </div>
            </div>
            <img src="/images/Vector 1.svg" alt="Vector Image" style={styles.vectorImage} width={isMobile ? '90%' : ''} />
            <PricingPlans screen='pricing' />
        </div>
    );
}

export default PricingHero;
