import { Button, Grid, Checkbox, Card } from '@mui/material';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import EastIcon from '@mui/icons-material/East';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Divider from '@mui/material/Divider';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function Step4({ onSubmit, onPrev, totalAddonPrice, loading }) {

    const navigate = useNavigate();
    const theme = useTheme();
    const matches_md = useMediaQuery(theme.breakpoints.down('md'))

    const handleSubmit = (e) => {
        onSubmit({});
    };

    let selectedAddons = localStorage.getItem('selectedAddons')



    return (
        <div style={{ width: matches_md ? '90vw' : '100%', display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: '100%' }}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <div style={{ fontWeight: 600, fontSize: 20, }}>Summary</div>
                    </Grid>

                    <Grid item xs={12} style={{ marginTop: "1%" }}>
                        <Grid container spacing={1} padding={3} style={{ border: '1px solid gainsboro', borderRadius: 5 }}>
                            <Grid item xs={1}>
                                <ShoppingCartIcon sx={{ fontSize: matches_md ? 30 : 40, color: '#EA2024' }} />
                            </Grid>
                            <Grid item xs={11}>
                                <div style={{ fontWeight: 600 }}>Billing Details</div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 25 }}>
                                    <div>
LLC Formation Plan                                    </div>
                                    <div>
                                        ${JSON.parse(localStorage.getItem("selectedPlanAmount"))}
                                    </div>
                                </div>
                                <Divider style={{ margin: '12px 0px' }} />
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div>
                                        {selectedAddons}
                                    </div>
                                    <div>
                                        ${totalAddonPrice}
                                    </div>
                                </div>
                                <Divider style={{ margin: '12px 0px 60px 0px' }} />
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ fontWeight: 600 }}>
                                        Total
                                    </div>
                                    <div>
                                        ${JSON.parse(localStorage.getItem("selectedPlanAmount")) + totalAddonPrice}
                                    </div>
                                </div>
                                <Divider style={{ margin: '12px 0px 40px 0px' }} />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button startIcon={<KeyboardBackspaceIcon />} onClick={onPrev} style={{ gap: 10, background: 'black', color: 'white', margin: '44px 0px 44px' }}>
                            Back
                        </Button>
                        <Button endIcon={<EastIcon />} onClick={handleSubmit} style={{ gap: 10, background: '#EA2024', color: 'white', margin: '44px 0px 44px auto' }}>
                            {loading ? 'Place Order...' : 'Place Order'}
                        </Button>
                    </Grid>
                </Grid>

            </div>
        </div>
    );
}
