import Header from "../components/Header";
import { Grid, TextField, useMediaQuery, Autocomplete } from "@mui/material";
import RegisterForm from "../components/RegisterForm";
import { useState } from "react";
import { useTheme } from '@mui/material/styles';
import PricingTabs from "../components/PricingTabs";
import Footer from "../components/Footer";
import CTA from "../components/CTA";

export default function Register() {

    const [selectedState, setSelectedState] = useState(null);
    const theme = useTheme();
    const matches_sm = useMediaQuery(theme.breakpoints.down('sm'));
    const matches_md = useMediaQuery(theme.breakpoints.down('md'));

    const handleStateSelect = (event, newValue) => {
        const start = window.scrollY;
        const end = 300;
        const duration = 500;

        const startTime = performance.now();

        const animateScroll = (timestamp) => {
            const elapsed = timestamp - startTime;
            const progress = Math.min(elapsed / duration, 1);

            window.scrollTo(0, start + progress * (end - start));

            if (progress < 1) {
                window.requestAnimationFrame(animateScroll);
            }
        };

        window.requestAnimationFrame(animateScroll);

        setSelectedState(newValue);

        // localStorage.setItem('selectedState', JSON.stringify(newValue));
    };


    const states = [
        { label: "Florida", fee: 125 },
        { label: "Texas", fee: 300 },
        { label: "Montana", fee: 35 },
        { label: 'New Mexico', fee: 50 },
        { label: 'Missouri', fee: 50 },
        { label: 'Colorado', fee: 50 },
        { label: "Delaware", fee: 140 },
        { label: 'New Hampshire', fee: 102 },
        { label: "Wyoming", fee: 102 },

        { label: 'Alabama', fee: 236 },
        { label: 'Alaska', fee: 250 },
        { label: 'Arizona', fee: 85 },
        { label: 'Arkansas', fee: 45 },
        { label: 'California', fee: 70 },
        { label: 'Connecticut', fee: 120 },
        { label: 'District of Columbia', fee: 99 },
        { label: 'Georgia', fee: 100 },
        { label: 'Hawaii', fee: 51 },
        { label: 'Idaho', fee: 101 },
        { label: 'Illinois', fee: 153 },
        { label: 'Indiana', fee: 97 },
        { label: 'Iowa', fee: 50 },
        { label: 'Kansas', fee: 166 },
        { label: 'Kentucky', fee: 40 },
        { label: 'Louisiana', fee: 105 },
        { label: 'Maine', fee: 175 },
        { label: 'Maryland', fee: 155 },
        { label: 'Massachusetts', fee: 520 },
        { label: 'Michigan', fee: 50 },
        { label: 'Minnesota', fee: 155 },
        { label: 'Mississippi', fee: 53 },
        { label: 'Nebraska', fee: 102 },
        { label: 'Nevada', fee: 425 },
        { label: 'New Jersey', fee: 129 },
        { label: 'New York', fee: 205 },
        { label: 'North Carolina', fee: 128 },
        { label: 'North Dakota', fee: 135 },
        { label: 'Ohio', fee: 99 },
        { label: 'Oklahoma', fee: 104 },
        { label: 'Oregon', fee: 100 },
        { label: 'Pennsylvania', fee: 125 },
        { label: 'Rhode Island', fee: 156 },
        { label: 'South Carolina', fee: 125 },
        { label: 'South Dakota', fee: 150 },
        { label: 'Tennessee', fee: 307 },
        { label: 'Utah', fee: 56 },
        { label: 'Vermont', fee: 125 },
        { label: 'Virginia', fee: 100 },
        { label: 'Washington', fee: 200 },
        { label: 'West Virginia', fee: 130 },
        { label: 'Wisconsin', fee: 130 },
    ];
    


    return (<div>
        <Header />
        <Grid container spacing={0} style={{ display: 'flex', justifyContent: 'center', width: matches_md ? '100%' : '100%' }}>
            <div style={{ width: '100%', marginTop: matches_md ? '10%' : '5%', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                <Grid container spacing={0} style={{ margin: 0 }}>
                    <Grid item xs={12}>
                        <center>
                            <h2 className='featuresHeading'>Simplified<span className='gradientText'> Pricing </span>for all your needs</h2>
                            <p className='featuresPara' style={{ marginTop: '1%', marginBottom: '1%' }}>Get upfront, clear pricing for starting and running your business.</p><br />
                        </center>
                    </Grid>
                </Grid>

                <Grid container spacing={0} style={{ display: 'flex', justifyContent: 'center', width: matches_md ? '90%' : '20%' }}>
                    <Grid item xs={12}>
                        <Autocomplete
                            disablePortal
                            options={states}
                            value={selectedState}
                            onChange={handleStateSelect}
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => <TextField {...params} label="Select state" />}
                        />
                    </Grid>
                </Grid>

                {
                    selectedState ? <PricingTabs state={selectedState?.label} fee={selectedState?.fee}
                        busiLink="https://wa.me/13022098440?text=Hello%20Team%20Boosty%2C%20%0A%0AI'm%20interested%20in%20placing%20an%20order%20for%20your%20Business%20Package.%20%F0%9F%98%8A%20What%20specific%20details%20do%20you%20require%20from%20me%20to%20initiate%20the%20process%3F%0A"
                        preLink="https://wa.me/13022098440?text=Hello%20Team%20Boosty%2C%20%0A%0AI'm%20interested%20in%20placing%20an%20order%20for%20your%20Premium%20Package.%20%F0%9F%98%8A%20What%20specific%20details%20do%20you%20require%20from%20me%20to%20initiate%20the%20process%3F"
                    /> : <></>
                }
            </div >
        </Grid>
        <div style={{ marginTop: '4%' }}><CTA /></div>
        <Footer />
    </div>)
}