import React, { useState } from 'react';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  TextField,
  Select,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EastIcon from '@mui/icons-material/East';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const roles = [
  'Owner', 'Manager', 'Employee', 'CEO', 'Director', 'Supervisor',
  'Coordinator', 'Team Lead', 'Analyst', 'Consultant', 'Engineer',
  'Designer', 'Developer', 'Marketing Specialist', 'Sales Representative',
  'Customer Support', 'Human Resources', 'Finance', 'IT Administrator',
  'Quality Assurance', 'Operations',
];

export default function Step2({ members, setMembers, onPrev, onNext }) {
  const theme = useTheme();
  const matches_md = useMediaQuery(theme.breakpoints.down('md'));
  const [expanded, setExpanded] = useState(members.length > 0 ? members[0].id : false);

  const handleError = (memberId, error, label) => {
    setMembers((prevMembers) =>
      prevMembers.map((member) =>
        member.id === memberId ? { ...member, errors: { ...member.errors, [label]: error } } : member
      )
    );
  };

  const validation = (member) => {
    let error = false;
    const { firstName, lastName, roleInCompany, phoneNumber, address } = member;

    if (!firstName.trim()) {
      error = true;
      handleError(member.id, 'Please input First Name', 'firstName');
    }
    if (!lastName.trim()) {
      error = true;
      handleError(member.id, 'Please input Last Name', 'lastName');
    }
    if (!roleInCompany.trim()) {
      error = true;
      handleError(member.id, 'Please input Role in Company', 'roleInCompany');
    }
    if (!phoneNumber.trim()) {
      error = true;
      handleError(member.id, 'Please input Phone Number', 'phoneNumber');
    }
    if (!address.trim()) {
      error = true;
      handleError(member.id, 'Please input Address', 'address');
    }
    return error;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const hasError = members.some((member) => validation(member));
    if (!hasError) {
      onNext(members); // Proceed to the next step only if there are no validation errors
    }
  };

  const handleMemberChange = (memberId, field, value) => {
    setMembers((prevMembers) =>
      prevMembers.map((member) =>
        member.id === memberId ? { ...member, [field]: value } : member
      )
    );
  };

  const handleAddMember = () => {
    const newMember = {
      id: Date.now(),
      firstName: '',
      lastName: '',
      roleInCompany: '',
      phoneNumber: '',
      address: '',
      errors: {},
    };
    setMembers([...members, newMember]);
    setExpanded(newMember.id);
  };

  const handleDeleteMember = (memberId) => {
    setMembers(members.filter((member) => member.id !== memberId));
    if (expanded === memberId && members.length > 1) {
      setExpanded(members[0].id);
    }
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div style={{ width: matches_md ? '90vw' : '100%', display: 'flex', justifyContent: 'center' }}>
      <div style={{ width: '100%' }}>
        <form onSubmit={handleSubmit}>
          <Grid item md={12} style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <div style={{ fontWeight: 500 }}>Members Details</div>
            </div>
            <Button
              startIcon={<AddIcon />}
              onClick={handleAddMember}
              style={{ textTransform: 'capitalize', gap: 10, background: '#EA2024', color: 'white' }}
            >
              ADD
            </Button>
          </Grid>

          {members.map((member, index) => (
            <Accordion
              key={member.id}
              expanded={expanded === member.id}
              onChange={handleAccordionChange(member.id)}
              style={{ marginTop: '5%' }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>Member {index + 1}</AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={4}>

                  <Grid item md={6} style={{ marginTop: '1%', width: '100%' }}>
                    <TextField
                      value={member.firstName}
                      onChange={(e) => handleMemberChange(member.id, 'firstName', e.target.value)}
                      label="Enter First Name"
                      onFocus={() => handleError(member.id, '', 'firstName')}
                      error={!!member.errors.firstName}
                      helperText={member.errors.firstName}
                      fullWidth
                    />
                  </Grid>

                  <Grid item md={6} style={{ marginTop: '1%', width: '100%' }}>
                    <TextField
                      value={member.lastName}
                      onChange={(e) => handleMemberChange(member.id, 'lastName', e.target.value)}
                      label="Enter Last Name"
                      onFocus={() => handleError(member.id, '', 'lastName')}
                      error={!!member.errors.lastName}
                      helperText={member.errors.lastName}
                      fullWidth
                    />
                  </Grid>

                  <Grid item md={6}>
                    <PhoneInput
                      country={'us'}
                      value={member.phoneNumber}
                      onChange={(phone) => {
                        handleMemberChange(member.id, 'phoneNumber', phone);
                        if (!phone) {
                          handleError(member.id, 'Please input Phone Number', 'phoneNumber');
                        } else {
                          handleError(member.id, '', 'phoneNumber');
                        }
                      }}
                      onBlur={() => {
                        if (!member.phoneNumber) {
                          handleError(member.id, 'Please input Phone Number', 'phoneNumber');
                        }
                      }}
                      inputStyle={{ width: '100%' }}
                    />
                    {member.errors.phoneNumber && (
                      <div style={{ color: '#EA2024', fontSize: 12 }}>{member.errors.phoneNumber}</div>
                    )}
                  </Grid>
                  <Grid item md={4} style={{ width: '100%' }}>
                    <FormControl fullWidth>
                      <InputLabel id={`role-select-label-${member.id}`}>Role in Company</InputLabel>
                      <Select
                        labelId={`role-select-label-${member.id}`}
                        value={member.roleInCompany}
                        label="Role in Company"
                        onChange={(e) => handleMemberChange(member.id, 'roleInCompany', e.target.value)}
                        onFocus={() => handleError(member.id, '', 'roleInCompany')}
                        error={!!member.errors.roleInCompany}
                      >
                        {roles.map((item, idx) => (
                          <MenuItem key={idx} value={item}>{item}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {member.errors.roleInCompany && (
                      <div style={{ color: '#EA2024', fontSize: 12 }}>{member.errors.roleInCompany}</div>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      type='text'
                      multiline
                      rows={4}
                      value={member.address}
                      onChange={(e) => handleMemberChange(member.id, 'address', e.target.value)}
                      onFocus={() => handleError(member.id, '', 'address')}
                      error={!!member.errors.address}
                      helperText={member.errors.address}
                      label="Enter Your Residential Address"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton onClick={() => handleDeleteMember(member.id)} style={{ color: '#EA2024' }}>
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          ))}

          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              startIcon={<KeyboardBackspaceIcon />}
              onClick={onPrev}
              style={{ textTransform: 'capitalize', background: '#EA2024', color: 'white' }}
            >
              Back
            </Button>
            <Button
              type="submit"
              endIcon={<EastIcon />}
              style={{ textTransform: 'capitalize', background: '#EA2024', color: 'white' }}
            >
              Next
            </Button>
          </Grid>
        </form>
      </div>
    </div>
  );
}
