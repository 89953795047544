import { Button, Grid, Checkbox } from '@mui/material';
import React, { useState } from 'react';
import EastIcon from '@mui/icons-material/East';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const addonsDetailsList = [
    { text: 'ITIN for Non-US Residents', price: '$300/One-Time', amt: 300 },
    { text: 'Annual Report Filing', price: '$50 + State Fee', amt: 50 },
    { text: 'Amazon Account Signup', price: '$100/One-Time', amt: 100 },
    { text: 'IRS Business Tax Filing', price: '$250/Year', amt: 250 },
    { text: 'High Street Bank Account', price: '$1500/One-Time', amt: 1500 },
    { text: 'Trademark Registration', price: '$750/One-Time', amt: 750 },
    { text: 'Professional Business Website', price: '$150/One-Time', amt: 150 },
];

export default function Step3({ selectedAddons, setSelectedAddons, totalAddonPrice, setTotalAddonPrice, onNext, onPrev }) {

    const theme = useTheme();
    const matches_md = useMediaQuery(theme.breakpoints.down('md'))

    // const [selectedAddons, setSelectedAddons] = useState([]);
    const [errors, setErrors] = useState({});

    const handleValue = (event, amt) => {
        if (event.target.checked) {
            setTotalAddonPrice(prev => prev + amt);
        } else {
            setTotalAddonPrice(prev => prev - amt);
        }
        const value = event.target.value;
        console.log(value);
        setSelectedAddons(prev => prev.includes(value) ? prev.filter(item => item !== value) : [...prev, value]
        );
    };

    const handleError = (error, label) => {
        setErrors(prev => ({ ...prev, [label]: error }));
    };

    // const validation = () => {
    //     let error = false;
    //     if (selectedAddons.length === 0) {
    //         error = true;
    //         handleError('Please select at least one addon', 'addons');
    //     }
    //     return error;
    // };

    const handleSubmit = (e) => {
        e.preventDefault();
        localStorage.setItem('selectedAddons', selectedAddons)
        onNext({ selectedAddons });
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', width: matches_md ? '90vw' : '100%' }}>
            <div style={{ width: '100%' }}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <div style={{ fontWeight: 600, fontSize: 20, }}>Addons Details</div>
                            <span style={{ fontSize: 14, color: '#A1A5B7' }}>
                                Select all the additional services you need.
                            </span>
                        </Grid>
                        {addonsDetailsList.map((item, index) => (
                            <Grid key={index} item xs={12} style={{ marginTop: '', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div>
                                    <Checkbox
                                        value={`${item.text} ${item.price}`}
                                        checked={selectedAddons.includes(`${item.text} ${item.price}`)}
                                        onChange={(e) => handleValue(e, item?.amt)}
                                        inputProps={{ 'aria-label': 'Checkbox demo' }}
                                    />
                                    <span style={{ fontSize: 14 }}>{item.text}</span>
                                </div>
                                <div>{item.price}</div>
                            </Grid>
                        ))}
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button startIcon={<KeyboardBackspaceIcon />} onClick={onPrev} style={{ gap: 10, background: 'black', color: 'white', margin: '44px 0px 44px' }}>
                                Back
                            </Button>
                            <Button endIcon={<EastIcon />} type="submit" style={{ gap: 10, background: '#EA2024', color: 'white', margin: '44px 0px 44px auto' }}>
                                Continue
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </div>
    );
}
