import { Paper, Button } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useTheme, useMediaQuery } from '@mui/material';
import { useNavigate } from "react-router-dom";

const PricingFeatures = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const navigate = useNavigate();

    const handleStartBusiness = () => {
        console.log('Navigating to /Start');
        navigate('/Start'); // Navigate to the desired route
    };
    
    const scrollToTop = () => {
        console.log('Scrolling to top');
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const services = [
        {
            title: 'Unique Business Address (Annually)',
            price: '$50/Year',
            description: 'A business address with unique suite number ensures easy Amazon approval, boosts credibility, verification, and regulatory compliance efficiently.',
            image: '/images/Paper Plane.png'
        },
        {
            title: 'ITIN for Non-US Residents',
            price: '$300/One-Time',
            description: 'Obtaining an ITIN is essential for PayPal and Stripe accounts. With it, you can later access credit and bank services.',
            image: '/images/Paper Plane.png'
        },
        {
            title: 'Annual Report Filing',
            price: '$50 + State Fee',
            description: 'File your business annual report to ensure compliance, avoid legal penalties, and keep your company in good standing with authorities.',
            image: '/images/Paper Plane.png'
        },
        {
            title: 'Amazon Account Signup',
            price: '$100/One-Time',
            description: 'Register with our Amazon expert for full compliance assistance, helping you create a verified seller account and meet all requirements.',
            image: '/images/Paper Plane.png'
        },
        {
            title: 'IRS Business Tax Filing',
            price: '$250/Year',
            description: 'File your IRS business taxes annually to guarantee accurate reporting, avoid penalties, and properly comply with regulations.',
            image: '/images/Paper Plane.png'
        },
        {
            title: 'High Street Bank Account',
            price: '$1500/One-Time',
            description: 'Open a bank account at institutions like Bank of America, Chase, or Wells Fargo for seamless, global financial transactions and access.',
            image: '/images/Paper Plane.png'
        },
        {
            title: 'Trademark Registration',
            price: '$750/One-Time',
            description: 'Register your trademark to protect your brand’s identity, ensuring legal rights, ownership, and protection against potential infringements.',
            image: '/images/Paper Plane.png'
        },
        {
            title: 'Professional Business Website',
            price: '$150/One-Time',
            description: 'Build a professional business website that boosts online presence, attracts customers, enhances credibility, and supports long-term business growth online.',
            image: '/images/Paper Plane.png'
        }
    ];

    const styles = {
        container: {
            backgroundImage: 'url(/images/Service-background.svg)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
            textAlign: 'center',
            flexDirection: 'column',
            marginBottom: '5%'
        },
        heading: {
            fontSize: isMobile ? 30 : '45px',
        },
        papersContainer: {
            marginBottom: '3%',
            display: 'flex',
            gap: '20px',
            flexWrap: 'wrap',
            justifyContent: 'center',
            textAlign: 'left'
        },
        paper: {
            width: isMobile ? '80%' : '250px',
            padding: '20px',
            background: 'linear-gradient(180deg, #F9F9F9 0%, #F8F8F8 100%)',
            borderRadius: '20px',
            boxShadow: '0px 14px 24px -15px #00000040',
            marginBottom: '20px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
        title: {
            marginTop: '5%',
            color: '#EA2024',
            fontSize: '15px',
            fontWeight: 600,
        },
        description: {
            fontSize: '14px',
            fontWeight: 300,
            marginTop: '5%',
            marginBottom: '5%',
        },
        button: {
            backgroundColor: '#EA2024',
            color: 'white',
            marginTop: '10px',
            textTransform: 'none',
            borderRadius: 12,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 'fit-content',
            padding: '12px 15px',
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'flex-start',
            marginBottom: '6%',
        },
        buttonIcon: {
            marginLeft: '5px',
        },
    };

    return (
        <div style={styles.container}>
            <h3 style={styles.heading}>Services</h3>
            <div style={styles.papersContainer}>
                {services.map((service, index) => (
                    <Paper key={index} style={styles.paper}>
                        <div>
                            <img src={service.image} alt={service.title} />
                            <div style={{ marginTop: '2%', fontWeight: 'bold' }}>{service.price}</div>
                            <div style={styles.title}>{service.title}</div>
                            <div style={styles.description}>
                                {service.description}
                            </div>
                        </div>
                        <div style={styles.buttonContainer}>
                        <Button
    onClick={() => {
        handleStartBusiness();
        scrollToTop();
    }}
    style={styles.button}>
    Apply Now
    <ArrowForwardIcon style={styles.buttonIcon} />
</Button>
                        </div>
                    </Paper>
                ))}
            </div>
        </div>
    );
};

export default PricingFeatures;
