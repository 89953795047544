import Header from "../components/Header";
import Benefits from "../components/Benefits";
import Support from "../components/Support";
import CTA from "../components/CTA";
import Footer from "../components/Footer";
import Servicesab from "../components/ServicesAb";

export default function About() {
    return (
        <>
            <Header />
            <Servicesab/>
            <Benefits />
            <Support />
            <CTA />
            <Footer />
        </>
    )
}