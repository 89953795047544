import { useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Typography,
  styled,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

const CustomAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: 'transparent',
  boxShadow: 'none',
  borderBottom: '1px solid #e0e0e0',
  borderRadius: 0,
  '&:before': {
    display: 'none',
  },
  '& .MuiAccordionSummary-root': {
    padding: theme.spacing(2.5, 0),
    minHeight: 'auto',
  },
  '& .MuiAccordionSummary-content': {
    margin: '0',
  },
  '& .MuiAccordionDetails-root': {
    padding: theme.spacing(3, 0),
  },
}))

export default function FAQSection() {
  const [expanded, setExpanded] = useState(false)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const faqs = [
    {
      question: 'Do I Need to Be a U.S. Citizen to Work with Buzz Filing?',
      answer: 'No, you do not need to be a U.S. citizen to work with Buzz Filing. We assist both citizens and non-citizens with business formation and filing services.',
    },
    {
      question: 'What Information Do You Need from Me to Get Started?',
      answer: 'We need basic information such as your business name, address, contact details, and the type of business structure you want to establish.',
    },
    {
      question: 'How Long Will It Take to File My Company?',
      answer: 'The filing process typically takes 5-7 business days, but processing times may vary depending on your state and the type of filing.',
    },
    {
      question: 'What is a Registered Agent?',
      answer: 'A Registered Agent is a person or entity designated to receive legal documents and official government correspondence on behalf of your business.',
    },
    {
      question: 'What Are the Differences Between EIN and ITIN?',
      answer: 'An EIN (Employer Identification Number) is for businesses, while an ITIN (Individual Taxpayer Identification Number) is for individuals who need a tax ID but are not eligible for a Social Security Number.',
    },
  ]

  return (
    <Box sx={{ width: '100%', py: { xs: 4, md: 6 } }}>
      <Container maxWidth="xl" sx={{ px: { xs: 2, sm: 3, md: 4 } }}>
        <Box textAlign="center" mb={6}>
          <Typography
            component="h1"
            sx={{
              color: '#ff0000',
              fontSize: { xs: '1.25rem', md: '1.5rem' },
              fontWeight: 700,
              mb: 2,
            }}
          >
            FAQ'S
          </Typography>
          <Typography
            component="h2"
            sx={{
              fontSize: { xs: '2rem', sm: '2.5rem', md: '3.5rem' },
              fontWeight: 900,
              mb: 2,
              fontFamily: '"Archivo Black", sans-serif',
            }}
          >
            Frequently Asked Questions
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: '1rem', md: '1.125rem' },
              color: 'text.secondary',
              maxWidth: '800px',
              margin: '0 auto',
            }}
          >
            Have a question? Check our FAQs; it's likely been asked before!
          </Typography>
        </Box>

        <Box sx={{ maxWidth: '1200px', margin: '0 auto' }}>
          {faqs.map((faq, index) => (
            <CustomAccordion
              key={index}
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
              disableGutters
            >
              <AccordionSummary
                expandIcon={<AddIcon />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
                sx={{
                  '& .MuiAccordionSummary-expandIconWrapper': {
                    transform: 'none',
                    '&.Mui-expanded': {
                      transform: 'none',
                    },
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: '1.125rem', md: '1.25rem' },
                    fontWeight: 700,
                    color: 'text.primary',
                  }}
                >
                  {faq.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={{ color: 'text.secondary', fontSize: '1rem' }}>
                  {faq.answer}
                </Typography>
              </AccordionDetails>
            </CustomAccordion>
          ))}
        </Box>
      </Container>
    </Box>
  )
}
