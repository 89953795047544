import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./website/pages/Home";
import './App.css';
import Pricing from "./website/pages/Pricing";
import About from "./website/pages/About";
import FaqPage from "./website/pages/FaqPage";
import Starts from "./website/pages/Starts";
import Register from "./website/pages/Register";
import Success from "./website/pages/Success";
import PrivacyPolicy from "./website/pages/PrivacyPolicy";
import Terms from "./website/pages/Terms";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route element={<Home />} path="/" />
          <Route element={<Pricing />} path="/pricing" />
          <Route element={<PrivacyPolicy />} path="/privacy-policy" />
          <Route element={<Terms />} path="/terms-and-conditions" />
          <Route element={<About />} path="/about" />
          <Route element={<FaqPage />} path="/faq" />
          {/* <Route element={<Contact />} path="/contact" /> */}
          {/* <Route element={<CompanyNameSearch />} path="/company" /> */}
          {/* <Route element={<StateFees />} path="/statefees" /> */}
          <Route element={<Starts />} path="/order" />
          <Route element={<Register />} path="/start" />
          <Route element={<Success />} path="/success" />
          <Route element={<Home />} path="*" />
          {/* <Route element={<TaxEstimator />} path="/new" /> */}

        </Routes>
      </Router>
    </div>
  );
}

export default App;
