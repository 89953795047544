import { Button, useMediaQuery } from "@mui/material"
import { Link, useLocation, useNavigate } from "react-router-dom"
// import { useSelector } from "react-redux"
import Header from "../components/Header"
import Footer from "../components/Footer"
import { primaryColor } from "../../constant";

export default function Success() {

    const location = useLocation()
    let status = location?.state?.status
    // const { user_data } = useSelector(state => state.user);
    const isDesktop = useMediaQuery('(min-width: 768px)');

    const navigate = useNavigate()

    const success_div = {
        background: '#F4F4F4',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        margin: '0 auto',
        padding: '8% 2%',
    }

    const success_box = {
        width: isDesktop ? '35%' : '90%',
        margin: 'auto',
        marginTop: isDesktop ? '' : '15%',
        padding: '2% 2% 3%',
        background: 'white',
        display: 'flex',
        justifyContent: 'center',
        borderRadius: 15,
        alignItems: 'center',
        flexDirection: 'column',
    }

    const handleClick = () => {
        navigate('/contact')
        window.scrollTo(0, 0)
    }

    const handleDashboard = () => {
        // navigate('/dashboard', { state: { user_profile_details: user_data } })
        window.scrollTo(0, 0)
    }

    const btnStyles = {
        padding: '13px 40px',
        fontSize: '14px',
        color: '#fff',
        backgroundColor: primaryColor,
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        width: '100%',
        marginTop: '20px',
    }

    // let image = status === 'success' ? 'success' : 'failure'
    // let title = status === 'success' ? 'Thanks a lot for putting up this Oreder!' : 'Payment failed, something went wrong!'

    return (
        <>
            <Header />
            <div style={success_div}>
                <div style={success_box}>
                    <img src={'https://oredomotor.com/images/success.svg'} style={{ width: 130, marginTop: '-17%', marginBottom: '3%' }} />
                    <h3 className="global-h3" style={{ margin: '2% 0', fontSize: 20 }}>'Thanks a lot for putting up this Order!'</h3>
                    {/* <p style={{ textAlign: 'center', fontSize: 13, opacity: '70%' }}>
                        Your Mailto order ASK123456 has successfully been placed. You'll fin all the details about your order below, and we'll send you a shipping confrimation email as soon as your order ships. In the meantime, you can share Mailto and earn store credit.
                    </p> */}
                    <p style={{ textAlign: 'center', fontSize: 13, marginTop: '1%', opacity: '70%' }}>
                        Questions? Suggestions? insightful showe thoughts?
                    </p>
                    <p style={{ color: primaryColor, fontWeight: 500, textDecoration: 'underline' }}>
                        hello@buzzfilling.com
                    </p>
                    <Link to={'/'} target="_blank">
                        <Button style={btnStyles}>Back to Home</Button>
                    </Link>
                </div>
            </div>
            <Footer />
        </>
    )
}