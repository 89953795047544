import { Paper } from "@mui/material";
import { useTheme, useMediaQuery } from '@mui/material';

const Advantages = () => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const styles = {
        container: {
            width: '100%',
            marginTop: '5%',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        paper: {
            width: '280px',
            textAlign: 'left',
            padding: '20px',
            background: 'linear-gradient(180deg, #F9F9F9 0%, #F8F8F8 100%)',
            borderRadius: '20px',
            boxShadow: '0px 14px 24px -15px #00000040',
        },
        title: {
            marginTop: '5%',
            color: '#EA2024',
            fontSize: '17px',
            fontWeight: 600,
        },
        description: {
            fontSize: '15px',
            fontWeight: 300,
            marginTop: '5%',
            marginBottom: '5%',
        },
    };

    return (
        <div style={styles.container}>
            <div>
                <h3 style={{ color: '#EA2024',fontSize: isMobile ? '25px' : '34px', textAlign: 'center' }}>Benefits</h3>
                <div style={{ marginTop: 0, fontSize: isMobile ? '20px' : '50px',fontWeight: 600, textAlign: 'center' }}>The Buzz Filing Advantage</div>
            </div>
            <div style={{ marginTop:isMobile?'10%': '5%', marginBottom: '3%', display: 'flex', gap: '20px',flexDirection:isMobile?'column':'row' }}>
                <Paper style={styles.paper}>
                    <img src="/images/Paper Plane.png" alt="Half colored img" />
                    <div style={styles.title}>Quick Delivery</div>
                    <div style={styles.description}>
                    Our efficient processes guarantee swift company formation in the US for faster business launch.
                    </div>
                </Paper>
                <Paper style={styles.paper}>
                    <img src="/images/Paper Plane.png" alt="Half colored img" />
                    <div style={styles.title}>Expert Support</div>
                    <div style={styles.description}>
                    Dedicated experts are here to provide reliable support, ensuring a smooth experience at every step.
                    </div>
                </Paper>
                <Paper style={styles.paper}>
    <img src="/images/Paper Plane.png" alt="Half colored img" />
    <div style={styles.title}>Competitive Pricing</div>
    <div style={styles.description}>
        We offer high-quality services at affordable rates, delivering great value without sacrificing results.
    </div>
</Paper>

                
            </div>
        </div>
    );
};

export default Advantages;
