import React from 'react';
import { Box, Typography, Button, useTheme, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { AiOutlineMail } from 'react-icons/ai';
import { FiPhone } from 'react-icons/fi';
import { HiOutlineLocationMarker } from 'react-icons/hi';

const Footer = () => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const matches_md = useMediaQuery(theme.breakpoints.down('md'));

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const styles = {
        container: {
            width: '100%',
            padding: '0 10% 3%',
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: isMobile ? 'center' : 'space-between',
            alignItems: isMobile ? 'center' : '',
            gap: '30px',
            color: '#333',
            overflow: 'hidden',
            boxSizing: 'border-box',
            marginBottom: isMobile ? '13%' : ''
        },
        column: {
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
        },
        heading: {
            fontWeight: 500,
            fontSize: '16px',
            marginBottom: '10px',
            color: '#000',
            textAlign: isMobile ? 'center' : 'left'
        },
        text: {
            textAlign: isMobile ? 'center' : 'left',
            fontWeight: '300',
            color: '#666',
            fontSize: '13px',
            textDecoration: 'none',
        },
        button: {
            backgroundColor: '#25D366',
            color: 'white',
            fontWeight: '500',
            padding: '10px 20px',
            width: '300px',
            borderRadius: '4px',
            '&:hover': {
                backgroundColor: '#1ebe5b',
            },
            display: 'flex',
            alignItems: 'center',
            gap: '8px'
        },
        divider: {
            width: '100%',
            height: '1px',
            backgroundColor: '#BDBDBD',
            margin: '4% 0',
        },
        contactContainer: {
            display: 'flex',
            justifyContent: isMobile ? 'center' : 'space-between',
            gap: '40px',
            padding: '20px 0',
            color: '#666',
            flexDirection: isMobile ? 'column' : 'row',
        },
        contactItem: {
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
        },
        copyright: {
            padding: '20px 0',
        },
        logo: {
            width: matches_md ? '40%' : '20%',
        },
    };

    return (
        <>
            <div style={{ paddingTop: '0.2%' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginLeft: '10%', marginTop: '5%', flexDirection: isMobile ? 'column' : '' }}>
                    <span><img src="/images/buzz-filling-logo.png" alt="LOGO" style={{ ...styles.logo, marginLeft: isMobile ? '-4%' : '' }} /></span>
                    <span style={{ width: isMobile ? '90%' : '100%', textAlign: 'left', marginRight: '8%', fontSize: 14, fontWeight: 400, marginTop: isMobile ? '8%' : '' }}>
                        Buzz Filing is your trusted partner for LLC formations, trademark registration, tax services, and banking solutions in the United States.
                    </span>
                </div>
                <div style={styles.divider}></div>

                <Box sx={styles.container}>
                    <Box sx={styles.column}>
                        <Typography sx={styles.heading}>Company</Typography>
                        <Link to="/" style={styles.text} onClick={scrollToTop}>Home</Link>
                        <Link to="/pricing" style={styles.text} onClick={scrollToTop}>Pricing</Link>
                        <Link to="/about" style={styles.text} onClick={scrollToTop}>About</Link>
                        <Link to="/faq" style={styles.text} onClick={scrollToTop}>FAQ</Link>
                    </Box>
                    <Box sx={styles.column}>
                        <Typography sx={styles.heading}>Support</Typography>
                        <a href="https://wa.link/wh0p7a" target="_blank" rel="noopener noreferrer" style={styles.text}>WhatsApp</a>
                        <a href="tel:+1234567890" style={styles.text} onClick={scrollToTop}>Call</a>
                        <a href="mailto:support@example.com" style={styles.text} onClick={scrollToTop}>Email</a>
                    </Box>
                    <Box sx={styles.column}>
                        <Typography sx={styles.heading}>Resources</Typography>
                        <a href="https://medium.com/@buzzfiling/understanding-llcs-a-comprehensive-guide-to-limited-liability-companies-8c650a6a8790" style={styles.text}>Limited Liability Company</a>
                        <a href="https://medium.com/@buzzfiling/ein-vs-itin-understanding-the-differences-in-u-s-tax-identification-numbers-f71f8d64a952" style={styles.text}>EIN & ITIN</a>
                        <a href="https://medium.com/@buzzfiling/how-non-u-s-residents-can-use-stripe-and-paypal-with-a-u-s-llc-28b9f3b425e5" style={styles.text}>Stripe & PayPal</a>
                        <a href="https://medium.com/@buzzfiling/how-starting-a-u-s-business-can-strengthen-your-visa-application-and-boost-approval-odds-cb12198b0ec6" style={styles.text}>Visa Approval with LLC</a>
                    </Box>
                    <Box sx={styles.column}>
                        <Typography sx={styles.heading}>Legal</Typography>
                        <Link to="/privacy-policy" style={styles.text} onClick={scrollToTop}>Privacy</Link>
                        <Link to="/terms-and-conditions" style={styles.text} onClick={scrollToTop}>Terms</Link>
                    </Box>
                    <Box sx={{ ...styles.column, alignItems: isMobile ? 'center' : 'start' }}>
                        <Typography sx={styles.heading}>Instant Support via WhatsApp</Typography>
                        <Typography sx={styles.text}>
                            Reach out to us directly on WhatsApp for quick support. We're here to help!
                        </Typography>
                        <br />
                        <Button
                            sx={{ ...styles.button, textAlign: 'center' }}
                            startIcon={<WhatsAppIcon />}
                            target='blank'
                            href="https://wa.link/wh0p7a"
                        >
                            Chat on WhatsApp
                        </Button>
                    </Box>
                </Box>

                {/* Responsive Contact Info Section */}
                <Box
                    sx={{
                        ...styles.contactContainer,
                        flexDirection: { xs: 'column', sm: 'row' },
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: { xs: 1, sm: 3 },
                        p: { xs: 2, sm: 3 },
                        textAlign: 'center',
                    }}
                >
                    <Box sx={{ ...styles.contactItem, gap: 1 }}>
                        <AiOutlineMail size={24} color="#333" />
                        <Typography variant="body2" sx={{ ...styles.text, fontSize: { xs: '14px', sm: '16px' } }}>
                            hello@buzzfiling.com
                        </Typography>
                    </Box>
                    <Box sx={{ ...styles.contactItem, gap: 1 }}>
                        <FiPhone size={24} color="#333" />
                        <Typography variant="body2" sx={{ ...styles.text, fontSize: { xs: '14px', sm: '16px' } }}>
                            +1 (983) 983-3227
                        </Typography>
                    </Box>
                    <Box sx={{ ...styles.contactItem, gap: 1 }}>
                        <HiOutlineLocationMarker size={24} color="#333" />
                        <Typography variant="body2" sx={{ ...styles.text, fontSize: { xs: '14px', sm: '16px' }, maxWidth: '250px' }}>
                            5900 Balcones Drive STE 100 Austin, TX 78731
                        </Typography>
                    </Box>
                </Box>

                {/* Copyright Section */}
                <Box sx={{ ...styles.copyright, mt: 2 }}>
                    <Typography
                        variant="body2"
                        sx={{
                            ...styles.text,
                            textAlign: 'center',
                            color: '#666',
                            fontSize: { xs: '12px', sm: '14px' },
                            p: { xs: 1, sm: 2 },
                        }}
                    >
                        Copyright© 2024 Buzz Global Inc. All Rights Reserved.
                    </Typography>
                </Box>
            </div>
        </>
    );
};

export default Footer;
