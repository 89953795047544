import Header from "../components/Header";
import Footer from "../components/Footer";

export default function Terms() {

    return (
        <>
            <Header />
            <div style={{ background: "linear-gradient(to bottom, #f4f4f4, white)", padding: '4% 8%' }}>
                <h2 style={{ color: 'black', margin: 0, fontWeight: 500, fontSize: 40, textAlign: 'left', marginBottom: 0 }}>
                    Terms and Conditions
                </h2>
            </div>
            <div style={{ padding: '2% 8%' }}>

                <div style={{ lineHeight: '1.6', marginTop: 0 }}>
                    <p><strong>PLEASE READ THIS LEGAL DISCLAIMER AND TERMS OF SERVICE CAREFULLY BEFORE ACCESSING OUR WEBSITE OR USING OUR SERVICES</strong></p>
                    <p>A visitor to the Website (as defined below), current Customer (as defined below) or prospective Customer is subject to this Legal Disclaimer and Terms of Service (“Terms”), as set forth below.</p>

                    <h2>You and Us</h2>
                    <p>Welcome to Buzz Filing (Buzz Global Inc.). We provide our services (“Services”) online, including via our website at <a href="https://www.buzzfiling.com">www.buzzfiling.com</a> or any website owned by Buzz Filing (collectively the “Website”), and other forms of communications such as email. Providing information on the Website also constitutes part of the Services.</p>
                    <p>We use the term “User” or “you” or “your” or “Visitor” or “Customer” to mean any past, current, or prospective customer of our Services as well as any visitor to the Website. These Terms apply to each Customer. There will be no fees for Customers to use the Services unless stated explicitly in these Terms or in other notices from Buzz Filing to Customers such as information on the Website.</p>

                    <h2>Agreement to the Terms</h2>
                    <p>These Terms govern your access to and use of our Services. By accessing or using the Services (including accessing the Website), you agree to be bound by these Terms as if these Terms were signed by you in ink on a hard-copy agreement. We may also ask you to confirm that you agree to these Terms, including by taking particular actions, such as clicking a button labelled “I Agree” or “Buy Now” or using the Services. Any personally-identifiable information about you or anyone else may be stored on or through the Services (“Personal Data”). So long as you are a Customer, Buzz Filing hereby grants you permission to use the software (“Software”) included in the Website as part of the Services. Your right to use the Software is revocable by Buzz Filing, and is not sublicensable. Moreover, the Software must be used solely for personal use by you.</p>
                    <p>The information provided in the Website or via any other means of transmission from Buzz Filing is not legal advice, but general information. The content contained on the Website or information contained in any other transmission from Buzz Filing is subject to these Terms.</p>
                    <p>Buzz Filing reserves the right to change or update these Terms at any time. Changes or updates of these Terms will appear on the Website and/or be communicated to Customer and are effective immediately. Use of the Website or receipt of Services after any such changes constitutes your consent to such changes and updates.</p>

                    <h2>Informational Purposes Only</h2>
                    <p>The purpose and intent of Buzz Filing are to provide you with general information, and not to provide any specific advice (legal or otherwise). The information presented is provided solely for informational purposes and constitutes an advertisement for services. Buzz Filing does not wish to represent anyone desiring legal representation based upon viewing the Website or information provided via email, facsimile, phone conversation, or any other form of transmission. Visitors or recipients of this information should not act upon this information without consulting with legal counsel. None of the information on the Website constitutes professional or legal advice or a recommendation by Buzz Filing, its representatives, agents, or otherwise.</p>
                    <p>The transmission and receipt of materials provided by Buzz Filing is not intended to and does not create an attorney-client relationship. Also, providing any of the information made available at the Website or via other forms of transmission does not create a business, legal, or professional relationship.</p>

                    <h2>Information Provided As-Is</h2>
                    <p>Information obtained from Buzz Filing or the Website should NOT be used as a substitute for legal advice from an attorney. It is provided “as is”, is not guaranteed to be correct, complete or up-to-date, and Buzz Filing expressly disclaims all warranties and disclaims any and all liability of responsibility for loss, claim liability, or damage that is a result of or in any manner related to errors or omissions in the content provided by Buzz Filing or the Website.</p>

                    <h2>Content Guidelines</h2>
                    <p>You shall not upload, distribute, or otherwise publish through this Site any Content, information, or other material that (a) includes any bugs, viruses, worms, trap doors, Trojan horses, or other harmful code or properties; (b) is libelous, threatening, defamatory, obscene, indecent, pornographic, discriminatory, or could give rise to any civil or criminal liability under the laws of the U.S. or the laws of any other country that may apply; or (c) violates or infringes upon the copyrights, patents, trademarks, service marks, trade secrets, or other proprietary rights of any person.</p>

                    <h2>Minimum Age Requirement</h2>
                    <p>By accepting these Terms of Use through your use of the Site, you certify that you are 18 years of age or older. If you are under 18 years old, please use this Site only under the supervision of a parent or legal guardian.</p>

                    <h2>Privacy and Content Use</h2>
                    <p>Unless explicitly permitted by our company in advance, all materials, including images, text, illustrations, designs, icons, photographs, programs, music clips or downloads, video clips and written and other materials that are part of this Site (collectively, the “Contents”) are intended solely for personal, non-commercial use. You may not make any commercial use of any of the information provided on the Site or make any use of the Site for the benefit of another business. We reserve the right to refuse service, terminate accounts, and/or cancel orders in its discretion, including, without limitation, if we believe that customer conduct violates applicable laws or is harmful to our interests.</p>

                    <h2>Third-Party Sites</h2>
                    <p>The Website contains links to servers maintained by other businesses and organizations, which exist independently from Buzz Filing or the Website. Buzz Filing cannot provide any warranty about the accuracy or source of the information contained on any of these servers or the content of any file a Customer might download from these sites. No such third party is endorsed or recommended by us by virtue of the fact that links to their servers appear on the Website.</p>

                    <h2>Disclaimer of Warranties</h2>
                    <p>To the fullest extent permissible under applicable law, we disclaim any and all implied warranties and representations, including, without limitation, any warranties of merchantability, fitness for a particular purpose, title, and non-infringement.</p>

                    <h2>Limitation of Liability</h2>
                    <p>The liability of both parties under this Agreement is limited. The maximum liability of both parties will never exceed the total amount paid for Services under this Agreement during the 365 days immediately preceding the applicable claim.</p>

                    <p><strong>CUSTOMER HEREBY AGREES THAT CUSTOMER HAS READ AND AGREES WITH THIS LEGAL DISCLAIMER AND TERMS AND CONDITIONS IN ITS ENTIRETY.</strong></p>
                </div>

            </div>
            <Footer />
        </>
    )
}